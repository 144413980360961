import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../modal/modal.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from '@twist/utils';
import { ConfirmationModalOptions } from '../../modal/modal.service';

@Component({
  selector: 'ui-confirmation-modal',
  standalone: true,
  imports: [CommonModule, FaIconComponent, ReactiveFormsModule, SafeHtmlPipe],
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent extends ModalComponent<boolean>{
  viewModel= signal<ConfirmationModalOptions | undefined>(undefined);
}
