import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-page-error-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-error-status.component.html',
  styleUrl: './page-error-status.component.scss',
})
export class PageErrorStatusComponent {
  statusCode = input(200)
}
