import { BrowserMultiFormatReader  } from '@zxing/browser';
import { HTMLCanvasElementLuminanceSource } from '@zxing/browser/esm/common/HTMLCanvasElementLuminanceSource';
import {BinaryBitmap, HybridBinarizer, Result} from '@zxing/library';

export class BrowserMultiFormatReaderInverse extends BrowserMultiFormatReader  {

  static override createBinaryBitmapFromCanvas(canvas: HTMLCanvasElement) {
    const luminanceSource = new HTMLCanvasElementLuminanceSource(canvas);
    const invertedSource = luminanceSource.invert();
    const hybridBinarizer = new HybridBinarizer(invertedSource);
    return new BinaryBitmap(hybridBinarizer);
  }

  override decodeFromCanvas(canvas: HTMLCanvasElement): Result {
    const binaryBitmap = BrowserMultiFormatReaderInverse.createBinaryBitmapFromCanvas(canvas);
    return this.decodeBitmap(binaryBitmap);
  }
}
