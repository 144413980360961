import { inject, Injectable } from '@angular/core';
import { AuthStore } from '@twist/core';
import { OrganizationPermissions, OrganizationTypes, UserRoles } from '@twist/backbone-api-services/ciam';

export type SecurityConstraints = {
  /**
   * Indicates whether an organization is required.
   */
  requireOrganization: boolean;
  orgTypes: OrganizationTypes[];
  orgPermissions: OrgPermissions[];
  allowedRoles: UserRoles[]
}

export enum ExtraOrgPermissions{
  Any = "any"
}
export type OrgPermissions = OrganizationPermissions | ExtraOrgPermissions
export const OrgPermissions = {...OrganizationPermissions, ...ExtraOrgPermissions};


export const EmptySecurityConstraints: SecurityConstraints = {
  requireOrganization: false,
  orgTypes: [],
  orgPermissions: [],
  allowedRoles: []
};

@Injectable({ providedIn: 'root' })
export class SecurityConstraintsEvaluator {
  private _authStore = inject(AuthStore);

  public evaluate = async (constraints: SecurityConstraints): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      console.debug('SecurityConstraintsEvaluator - start', constraints);
      this._authStore.isAuthenticatedPromise()
        .then(async isAuthenticated => {
          if (!isAuthenticated) {
            resolve(false);
            console.debug('SecurityConstraintsEvaluator - not authenticate');
            return;
          }
          const organization = await this._authStore.getOrganization();
          const user = await this._authStore.getUser();

          if (constraints.requireOrganization && !organization) {
            resolve(false);
            console.debug('SecurityConstraintsEvaluator - organization is required but not found');
          }

          if (constraints.orgTypes.length > 0) {
            if (!organization || !constraints.orgTypes.includes(organization.type)) {
              resolve(false);
              console.debug('SecurityConstraintsEvaluator - not the correct org type', organization?.type);
              return;
            }
          }
          console.debug('SecurityConstraintsEvaluator - OK');
          resolve(true);
        })
        .catch(err => {
          console.debug('SecurityConstraintsEvaluator - ERROR');
          resolve(false);
        });
    });

  };

}
