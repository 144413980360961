import { AppSettings } from '../settings';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';

export function oAuthModuleConfigFactory(appSettings: AppSettings): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [appSettings.environment.backboneUrl],
      sendAccessToken: true
    }
  };
}
