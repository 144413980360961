import { AppSettings } from '../settings';
import { AuthConfig } from 'angular-oauth2-oidc';

export function authConfigFactory(appSettings: AppSettings): AuthConfig {
  return {
    issuer: appSettings.environment.ciamUrl,
    clientId: 'twist:portal',
    responseType: 'code',
    redirectUri: window.location.origin + '/',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    //logoutUrl: window.location.origin + '/signout-oidc',
    postLogoutRedirectUri: window.location.origin + '/',
    scope: 'openid profile email offline_access',
    requireHttps: appSettings.environment.production,
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshShowIFrame: false,
    //silentRefreshTimeout: 5000, // For faster testing
    //timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: true,
    sessionCheckIntervall: 10000,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
  };
}
