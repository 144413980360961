import { Component, computed, EventEmitter, input, Output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  /**
   * The total number of records.
   */
  public totalRecords = input.required<number>();

  /**
   * Defines the number of items to be displayed per page.
   */
  public pageSize = input<number>(5);

  /**
   * Represents the current page number in pagination.
   */
  public currentPage = input.required<number>();


  /**
   *  The number of buttons to show either side of the current page
   **/
  maxButtonOffset = input<number>(2);

  /**
   * Indicates whether to display "first" and "last" buttons in pagination controls.
   */
  showFirstLastButtons = input<boolean>(true);

  /**
   * Flag indicating whether to display next and previous buttons.
   */
  showNextPreviousButtons = input<boolean>(true);

  /**
   * Display small pagination buttons
   */
  smallButtons = input<boolean>(false);

  /**
   * A boolean variable indicating whether caption should be shown.
   */
  showCaption = input<boolean>(true);


  @Output()
  pageChanged = new EventEmitter<number>();


  protected pageNumbers: Signal<number[]> = computed(() => {
    return [...Array(Math.ceil(this.totalRecords() / this.pageSize())).keys()];
  });

  protected firstRecordNumberOnCurrentPage = computed(() => {
    return (this.currentPage() - 1) * this.pageSize() + 1;
  });

  protected lastRecordNumberOnCurrentPage = computed(() => {
    return Math.min(this.currentPage() * this.pageSize(), this.totalRecords());
  });

  /** Set page number */
  protected selectPageNumber(pageNumber: number) {
    this.pageChanged.emit(pageNumber);
  }

  /** Set next page number */
  protected next() {
    const nextPage = this.currentPage() + 1;
    nextPage <= this.pageNumbers().length && this.selectPageNumber(nextPage);
  }

  /** Set previous page number */
  protected previous() {
    const previousPage = this.currentPage() - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }
}

