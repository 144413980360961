import { inject, Injector } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { OffCanvasService } from './off-canvas.service';

export abstract class OffCanvasInitComponent {
  protected _router = inject(Router);
  protected _route = inject(ActivatedRoute);
  protected _offCanvasService = inject(OffCanvasService);
  protected _injector = inject(Injector);


  async close() {
    const urlWithoutAuxiliaryRoute = this._router
      .createUrlTree(['.'], { relativeTo: this._route })
      .root.children[PRIMARY_OUTLET].toString();

    await this._router.navigate([urlWithoutAuxiliaryRoute]);
  }
}
