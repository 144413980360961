import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageHelper {

  public save<T>(key: string, value: T | undefined): void {
    if (value) {
      this.saveString(key, JSON.stringify(value));
    } else {
      this.remove(key);
    }
  }

  public saveString(key: string, value: string | undefined): void {
    window.localStorage.removeItem(key);
    if (value) {
      window.localStorage.setItem(key, value);
    }
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  public get<T>(key: string): T | null {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
    return null;
  }

  public getString(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  public saveToSession<T>(key: string, value: T): void {
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public removeFromSession(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public getFromSession<T>(key: string): T | null {
    const value = window.sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as T;
    }
    return null;
  }
}
