import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../../components/loading/loading.component';

@Component({
  selector: 'ui-off-canvas-wrapper',
  standalone: true,
  imports: [CommonModule, LoadingComponent],
  host: { 'style': 'display: contents' },
  templateUrl: './off-canvas-wrapper.component.html',
})
export class OffCanvasWrapperComponent {
  isLoading = input<boolean>(false)
}
