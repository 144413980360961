export const Uint16MaxValue = 65535;
export const RgbMaxValue = 255;

export function getRgbValueFromUint16(value: number | undefined) {
  if (value) {
    return Math.round(value / (Uint16MaxValue / RgbMaxValue));
  }
  return 0;
}

export function getUint16FromRgbValue(value: number | undefined) {
  if (value) {
    return Math.round(value * (Uint16MaxValue / RgbMaxValue));
  } else {
    return 0;
  }
}

export function getPercentageFromUint16(value: number | undefined) {
  if (value) {
    return Math.round(value / (Uint16MaxValue / 100));
  }
  return 0;
}

export function getUint16FromPercentage(value: number | undefined) {
  if (value) {
    return Math.round(value * (Uint16MaxValue / 100));
  } else {
    return 0;
  }
}
