import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, effect, inject, untracked } from '@angular/core';
import { AuthService } from './auth.service';
import { filter, tap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { OAuthService } from 'angular-oauth2-oidc';
import { parseJwtToken } from './jwt-token';
import { LocalStorageHelper } from '@twist/utils';
import { DtoOrganizationAuthorizationInfo, DtoUserAuthorizationInfo } from '@twist/backbone-api-services/ciam';
import { AuthenticationContextService } from '../services';

export type AuthState = {
  idToken: string;
  accessToken: string;
  hasValidTokens: boolean;
  _userIdFromToken: string | undefined;
  _selectedOrganizationKey: string | undefined;
  _authInfoLoaded: boolean;
  organizationAuthInfo: DtoOrganizationAuthorizationInfo | undefined;
  userAuthInfo: DtoUserAuthorizationInfo | undefined;
}

export const initialAuthState: AuthState = {
  idToken: '',
  accessToken: '',
  hasValidTokens: false,
  _userIdFromToken: undefined,
  _selectedOrganizationKey: undefined,
  _authInfoLoaded: false,
  organizationAuthInfo: undefined,
  userAuthInfo: undefined
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withState<AuthState>(initialAuthState),
  withComputed(state => {
    const authService = inject(AuthService);

    const isDoneLoadingSignal = toSignal(authService.isDoneLoading$);
    const organizationKey = computed(() => {
      return state._selectedOrganizationKey() ?? state.organizationAuthInfo()?.key;
    });

    const loading = computed(() => !isDoneLoadingSignal() || !state._authInfoLoaded());

    return {
      isAuthenticated: toSignal(authService.isAuthenticated$),
      isLoading: loading,
      user: computed(() => state.userAuthInfo()),
      organization: computed(() => state.organizationAuthInfo()),
      organizationKey: organizationKey
    };
  }),
  withMethods(state => {

    const authService = inject(AuthService);
    const authenticationContextService = inject(AuthenticationContextService);
    const localStorageHelper = inject(LocalStorageHelper);

    const oauthService = inject(OAuthService);

    const loadAuthorizationContextEffect = effect(() => {
      const isAuthenticated = state.isAuthenticated();
      const organizationKey = state._selectedOrganizationKey();
      const userId = state._userIdFromToken();
      untracked(() => {
        if (isAuthenticated && userId) {
          authenticationContextService.getAuthenticationContext(userId)
            .then(context => {
              patchState(state, {
                _authInfoLoaded: true,
                userAuthInfo: context.user,
                organizationAuthInfo: context.organization
              });
              if (organizationKey != context.organization?.key) {
                switchOrganization(context.organization?.key);
              }
            })
            .catch(err => {
              patchState(state, {
                _authInfoLoaded: true,
                userAuthInfo: undefined,
                organizationAuthInfo: undefined
              });
            });
        } else {
          patchState(state, {
            _authInfoLoaded: false,
            userAuthInfo: undefined,
            organizationAuthInfo: undefined
          });
        }
      });
    });

    /**
     * A function that returns a Promise that will only resolve when the authService is done loading.
     */
    const isAuthenticatedPromise = (): Promise<boolean> => {
      return new Promise<boolean>((resolve, reject) => {
        authService.isDoneLoading$.pipe(
          filter(doneLoading => doneLoading),
          tap(() => {
            resolve(authService.hasValidToken());
          })
        ).subscribe();
      });
    };


    const isLoading$ = toObservable(state.isLoading)
    const getOrganization = (): Promise<DtoOrganizationAuthorizationInfo | undefined> => {
      return new Promise<DtoOrganizationAuthorizationInfo | undefined>((resolve, reject) => {
        isLoading$.pipe(
          filter(loading => !loading),
          tap(() => {
            resolve(state.organizationAuthInfo());
          })
        ).subscribe();
      });
    };

    const getUser = (): Promise<DtoUserAuthorizationInfo | undefined> => {
      return new Promise<DtoUserAuthorizationInfo | undefined>((resolve, reject) => {
        isLoading$.pipe(
          filter(loading => !loading),
          tap(() => {
            resolve(state.userAuthInfo());
          })
        ).subscribe();
      });
    };

    const switchOrganization = (orgKey: string | undefined) => {
      localStorageHelper.saveString('tw_org', orgKey);
      patchState(state, {
        userAuthInfo: undefined,
        organizationAuthInfo: undefined
        });
      window.location.reload();
    };

    oauthService.events
      .pipe(filter(e => ['user_profile_loaded'].includes(e.type)))
      .subscribe(e => {
        extractTokenDataToState();
      });

    oauthService.events
      .pipe(filter(e => ['silent_refresh_timeout'].includes(e.type)))
      .subscribe(e => {
        extractTokenDataToState();
      });

    const extractTokenDataToState = () => {
      isAuthenticatedPromise()
        .then(
          isAuthenticated => {
            if (isAuthenticated) {
              const idToken = oauthService.getIdToken();
              const accessToken = oauthService.getAccessToken();
              const idTokenData = parseJwtToken(idToken);
              patchState(state, {
                accessToken: accessToken,
                idToken: idToken,
                hasValidTokens: true,
                _userIdFromToken: idTokenData.sub
              });
            } else {
              patchState(state, {
                accessToken: undefined,
                idToken: undefined,
                hasValidTokens: false,
                _userIdFromToken: undefined
              });
            }
          }
        );
    };

    const extractOrganizationKeyFromLocalStorage = () => {
      const orgKey = localStorageHelper.getString('tw_org');
      if (orgKey) {
        patchState(state, {
          _selectedOrganizationKey: orgKey
        });
      }
    }

    const initialize = async () => {
      extractOrganizationKeyFromLocalStorage();
      extractTokenDataToState();
    };

    const login = (targetUrl?: string) => {
      authService.login(targetUrl);
    };

    const logout = () => {
      patchState(state, {
        userAuthInfo: undefined,
        organizationAuthInfo: undefined
      });
      oauthService.logOut();
    };

    const refreshTokens = () => {
      authService.refresh();
    };

    return {
      initialize,
      logout,
      login,
      refreshTokens,
      isAuthenticatedPromise,
      getOrganization,
      getUser,
      switchOrganization
    };
  }),
  withHooks({
    async onInit({ initialize }) {
      await initialize();
    }
  })
);
