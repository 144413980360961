import { Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { SvgIconComponent, SvgIcons } from '../svg-icon/svg-icon.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { SideNavConfig, SideNavTopLevelItem } from './side-nav.models';
import { AuthGuardDirective } from '@twist/core';

@Component({
  selector: 'ui-side-nav',
  standalone: true,
  imports: [CommonModule, NgScrollbar, RouterLink, RouterLinkActive, SvgIconComponent, NgbCollapse, AuthGuardDirective],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss'
})
export class SideNavComponent {
  protected readonly SvgIcons = SvgIcons;
  config = input.required<SideNavConfig>();
  isDashboardCollapsed: boolean = false;

  hasSubItems(topLevelItem: SideNavTopLevelItem) {
    return topLevelItem.subItems && topLevelItem.subItems.length > 0;
  }

  isCollapsed($index: number) {
    return signal(true);
  }
}
