import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {
  EmptySecurityConstraints,
  SecurityConstraints,
  SecurityConstraintsEvaluator
} from './security-constraints-evaluator';

@Directive({
  standalone: true,
  selector: '[authGuard]'
})
export class AuthGuardDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private securityConstraintsEvaluator: SecurityConstraintsEvaluator) {
  }

  @Input()
  set authGuard(securityConstraints: Partial<SecurityConstraints> | undefined) {
    this.viewContainer.clear();
    const mergedConstraints =
      {
        ...EmptySecurityConstraints,
        ...securityConstraints
      };
    this.securityConstraintsEvaluator.evaluate(mergedConstraints).then(canAccess => {

      if (canAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
