/**
 * Identity and Access Management
 * CIAM (Customer Identity and Access Management) API
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = MyOrganization__Info, 1 = MyOrganization__Members, 2 = MyOrganization__CustomRoles, 3 = MyOrganization__ProductBlueprints, 4 = MyOrganization__RoutineBlueprints, 5 = MyOrganization__ApiKeys, 6 = OrganizationManagement
 */
export enum OrganizationFeatures {
    MyOrganization__Info = 'my_organization__info',
    MyOrganization__Members = 'my_organization__members',
    MyOrganization__CustomRoles = 'my_organization__custom_roles',
    MyOrganization__ProductBlueprints = 'my_organization__product_blueprints',
    MyOrganization__RoutineBlueprints = 'my_organization__routine_blueprints',
    MyOrganization__ApiKeys = 'my_organization__api_keys',
    OrganizationManagement = 'admin__organization_management'
}

