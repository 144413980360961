import { inject, Injectable, Injector, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';


export type ModalSize = 'small' | 'medium' | 'large' | 'fullscreen'

export type ModalOptions = {
  injector?: Injector,
  size?: ModalSize;
}

/**
 * Options for configuring a confirmation modal.
 */
export type ConfirmationModalOptions = {
  title: string;
  /**
   * Represents the body content of the confirmation dialog.
   * This variable is optional and may contain string values that include HTML markup.
   */
  message: string
  warning?: string
  size: ModalSize;
  confirmButtonText: string;
  cancelButtonText: string;
}

const DefaultConfirmationModalOptions: Required<Omit<ConfirmationModalOptions, 'warning'>> = {
  title: 'Are you sure?',
  message: 'Do you really want to continue?',
  size: 'small',
  confirmButtonText: 'Continue',
  cancelButtonText: 'Cancel'
};

export type ModalReference<TComponent extends ModalComponent<TResult>, TResult> = {
  component: TComponent;
  result: Promise<TResult>;
}


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  #modalService = inject(NgbModal);

  open<TComponent extends ModalComponent<TResult>, TResult>(componentType: Type<TComponent>, options?: ModalOptions): ModalReference<TComponent, TResult> {

    const size = options?.size == 'large' ? 'lg' : options?.size == 'small' ? 'sm' : 'md';

    const ref = this.#modalService.open(componentType, {
      size: size,
      injector: options?.injector,
      backdrop: true,
      fullscreen: options?.size == 'fullscreen'
    });
    return {
      component: ref.componentInstance as TComponent,
      result: new Promise<TResult>((resolve, reject) => {
        ref.result.then(res => {
          resolve(res as TResult);
        }).catch(err => {
          reject(err);
        });
      })
    } as ModalReference<TComponent, TResult>;
  }

  confirm(options: string | Partial<ConfirmationModalOptions>): Promise<boolean> {

    if (typeof options === 'string') {
      options = {
        message: options
      };
    }

    const allOptions = {
      ...DefaultConfirmationModalOptions,
      ...options
    };

    const size = options?.size == 'large' ? 'lg' : options?.size == 'medium' ? 'md' : 'sm';

    const ref = this.#modalService.open(ConfirmationModalComponent, {
      size: size,
      backdrop: true,
      fullscreen: allOptions.size == 'fullscreen'
    });
    const component = ref.componentInstance as ConfirmationModalComponent;
    component.viewModel.set(allOptions);
    return new Promise<boolean>((resolve, reject) => {
      ref.result.then(res => {
        resolve(res as boolean);
      }).catch(err => {
        reject(err);
      });
    });
  }
}
