/**
 * Identity and Access Management
 * CIAM (Customer Identity and Access Management) API
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = DevTools
 */
export enum UserRoles {
    DevTools = 'dev_tools'
}

