import { inject } from '@angular/core';
import { ToastNotificationsService } from './toast-notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthStore } from '../authentication';

export abstract class ApiServiceBase {
  protected toastNotificationsService = inject(ToastNotificationsService);
  protected authStore = inject(AuthStore);

  protected async handleErrorAndReject(message: string, err: HttpErrorResponse | null, reject: (error?: HttpErrorResponse | null) => void) {
    switch (err?.status ?? 0) {
      case 401:
        this.authStore.refreshTokens();
        break;
      // TODO: other error messages
      default:
        this.toastNotificationsService.error(this.getMessage(err), message, err);
        break;
    }
    reject(err);
  }

  private getMessage(err: HttpErrorResponse | null): string{
    if (err === null) {
      return 'Unexpected error';
    }
    if (err.error){
      return typeof err.error === 'string' ? err.error : `${err.status} - ${err.error.title ?? ""} - ${err.error.detail ?? ""}`;
    }
    return `${err.status} - ${err.statusText}`;
  }
}
