<div *ngIf="viewModel() as vm">
  <div class="modal-header">
    <div>
      <h3 class="modal-title">
        {{ vm.title }}
      </h3>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal('Cross click')"></button>
  </div>
  <div class="modal-body border-bottom flex-grow-1">
    <div class="py-1 text-lg fs-6"
         [innerHTML]="vm.message! | safeHtml">
    </div>
  </div>
  @if (vm.warning) {
    <div class="modal-body flex-grow-1 bg-danger-subtle fs-6 border-0">
      <span [innerHTML]="vm.warning! | safeHtml"></span>
    </div>
  }
  <div class="modal-footer">
    <button type="button"
            (click)="dismissModal()"
            class="btn btn-outline-dark px-5">{{ vm.cancelButtonText }}
    </button>
    <button type="button"
            (click)="closeModal(true)"
            class="btn btn-danger px-5">{{ vm.confirmButtonText }}
    </button>
  </div>
</div>
