import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-off-canvas-body',
  standalone: true,
  host: { 'style': 'display: contents' },
  imports: [CommonModule],
  templateUrl: './off-canvas-body.component.html',
})
export class OffCanvasBodyComponent {}
