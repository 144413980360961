import { inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AuthenticationContextBackboneService,
  GetAuthorizationContextQueryResult
} from '@twist/backbone-api-services/ciam';

@Injectable({ providedIn: 'root' })
export class AuthenticationContextService {
  private _authenticationContextBackboneService = inject(AuthenticationContextBackboneService);

  getAuthenticationContext = (
    userId: string
  ): Promise<GetAuthorizationContextQueryResult> => {
    return new Promise<GetAuthorizationContextQueryResult>((resolve, reject) => {
      firstValueFrom(
        this._authenticationContextBackboneService.getAuthenticationContext(
          userId)
      )
        .then((response) => {
          if (response)
            resolve(response);
          else {
            reject(undefined);
          }
        })
        .catch(async (err: HttpErrorResponse | null) => {
          /*await this.handleErrorAndReject('Failed to load installation security details', err, reject);*/
        });
    });
  };



}
