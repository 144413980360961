<div class="row">
  <div class="col-sm-12 col-md-5 align-middle mb-2">
    @if (showCaption()) {
      <div role="status" aria-live="polite">Showing results {{ firstRecordNumberOnCurrentPage() }} to
        {{ lastRecordNumberOnCurrentPage() }} of {{ totalRecords() }}
      </div>
    }
  </div>
  <div class="col-sm-12 col-md-7 d-flex justify-content-end  mb-2 align-middle">
    <nav>
      <ul class="pagination"
          [class.pagination-sm]="smallButtons()">
        @if (showFirstLastButtons()) {
          <!-- first button -->
          <li class="page-item"
              [class.disabled]="currentPage() === 1">
            <button (click)="selectPageNumber(1)" class="page-link">««</button>
          </li>
        }
        @if (showNextPreviousButtons()) {
          <!-- previous button -->
          <li class="page-item"
              [class.disabled]="currentPage() === 1">
            <button (click)="previous()" class="page-link">«</button>
          </li>
        }
        <!-- page numbers -->
        @for (pageNumber of pageNumbers(); track pageNumber) {
          @if (pageNumber + 1 >= currentPage() - maxButtonOffset() && pageNumber + 1 <= currentPage() + maxButtonOffset()) {
            <li class="page-item"
                [class.active]="currentPage() === pageNumber">
              <button (click)="selectPageNumber(pageNumber)" class="page-link">{{ pageNumber }}</button>
            </li>
          }
        }

        @if (showNextPreviousButtons()) {
          <!-- next button -->
          <li class="page-item"
              [class.disabled]="currentPage() === pageNumbers().length">
            <button (click)="next()" class="page-link">»</button>
          </li>
        }
        @if (showFirstLastButtons()) {
          <!-- last button -->
          <li class="page-item"
              [class.disabled]="currentPage() === pageNumbers().length">
            <button (click)="selectPageNumber(pageNumbers().length)" class="page-link">»»</button>
          </li>
        }
      </ul>
    </nav>
  </div>
</div>
