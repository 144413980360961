import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  GuardResult,
  RedirectCommand,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthStore } from '@twist/core';
import {
  EmptySecurityConstraints,
  SecurityConstraints,
  SecurityConstraintsEvaluator
} from './security-constraints-evaluator';

export const authGuard = (securityConstraints?: Partial<SecurityConstraints>) => {
  const guard: CanActivateFn = async (route: ActivatedRouteSnapshot,
                                      state: RouterStateSnapshot): Promise<GuardResult> => {

    const authStore = inject(AuthStore);
    const securityConstraintsEvaluator = inject(SecurityConstraintsEvaluator);
    const router = inject(Router);

    const isAuthenticated = await authStore.isAuthenticatedPromise();
    if (!isAuthenticated) {
      authStore.login(state.url);
      return false;
    }

    const mergedConstraints =
      {
        ...EmptySecurityConstraints,
        ...securityConstraints
      };


    const canAccess = await securityConstraintsEvaluator.evaluate(mergedConstraints);
    if (!canAccess) {
      const loginPath = router.parseUrl('/errors/403');
      return new RedirectCommand(loginPath, { skipLocationChange: true });
    }
    return true;

  };

  return guard;
};
