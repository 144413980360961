import { inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';

export abstract class ModalComponent<TResult> {

  protected modalRef = inject(NgbActiveModal);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);

  dismissModal(reason?: any) {
    this.modalRef.dismiss(reason);
  }

  closeModal(result: TResult) {
    this.modalRef.close(result);
  }

  switchModal(newModalRoute: string, routeParameters: any[]) {
    const urlWithoutAuxiliaryRoute = this.router
      .createUrlTree(['.'], { relativeTo: this.route })
      .root.children[PRIMARY_OUTLET].toString();

    this.router.navigate([urlWithoutAuxiliaryRoute, { outlets: { modal: [newModalRoute, ...routeParameters] } }])
      .then(() => {
        setTimeout(() => {
          this.dismissModal();
        }, 3000);
      });
  }
}
