import { inject, Injectable, Injector, Type } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { OffCanvasComponent } from './off-canvas.component';

@Injectable({
  providedIn: 'root'
})
export class OffCanvasService {

  #offCanvasService = inject(NgbOffcanvas);

  open<TComponent extends OffCanvasComponent<TResult>, TResult>(componentType: Type<TComponent>, options?: OffCanvasOptions): OffCanvasReference<TComponent, TResult> {

    const sizeClass = options?.size == 'large' ? 'offcanvas-size-lg' : options?.size == 'medium' ? 'offcanvas-size-md' : '';

    const ref = this.#offCanvasService.open(componentType, {
      position: 'end',
      panelClass: sizeClass,
      injector: options?.injector
    });
    return {
      component: ref.componentInstance as TComponent,
      result: new Promise<TResult>((resolve, reject) => {
        ref.result.then(res => {
          resolve(res as TResult);
        }).catch(err => {
          reject(err);
        });
      })
    } as OffCanvasReference<TComponent, TResult>;
  }
}

export type OffCanvasSize = 'small' | 'medium' | 'large'


export type OffCanvasOptions = {
  size?: OffCanvasSize,
  injector?: Injector,
}

export type OffCanvasReference<TComponent extends OffCanvasComponent<TResult>, TResult> = {
  component: TComponent;
  result: Promise<TResult>;
}
