<ol class="wizard-steps" [class]="'wizard-steps--' + steps().length">
  @for (step of steps(); track step.index) {
    <li
      [class.active]="step.active"

      [class.completed]="step.completed"
      [class.disabled]="step.disabled">
      <span>{{ step.label }}</span>
    </li>
  }
</ol>
