import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AppSettings } from '../settings';
import { Logger } from '@twist/logging';
import { Observable } from 'rxjs';
import { AuthStore } from '@twist/core';

/**
 * Interceptor that adds an API key to the request headers for API calls.
 */
@Injectable()
export class AddOrganizationKeyInterceptor implements HttpInterceptor {

  private _authStore = inject(AuthStore);
  private _appSettings = inject(AppSettings);
  private _logger = inject(Logger)

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isApiUrl(request) && this._authStore.organizationKey()) {
      this._logger.debug('[AddOrganizationKeyInterceptor] - 🔑', request.url);
      const apiRequest = request.clone({
        setHeaders: {
          'X-Tw-Org' : this._authStore.organizationKey() ?? ""
        }
      });
      return next.handle(apiRequest);
    }
    this._logger.debug('[AddOrganizationKeyInterceptor] - ignored', request.url, this._authStore.organizationKey());
    return next.handle(request);
  }

  private isApiUrl(request: HttpRequest<any>): boolean {
    return request.url.startsWith(this._appSettings.environment.backboneUrl);
  }
}


