<!-- Pre-loader -->
<div id="preloader">
  <div id="status">
    @if (showLogo()) {
      <div class="logo">
        <ui-twist-logo variant="compact" height="3em"></ui-twist-logo>
      </div>
    }
    <div class="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<!-- End Preloader-->
