<div class="offcanvas-header">
  <h4 class="offcanvas-title" id="offcanvas-title">
    {{ title() }}
  </h4>
  <button
    (click)="dismissOffCanvas()"
    aria-describedby="offcanvas-title"
    class="btn-close"
    type="button"
  ></button>
</div>
