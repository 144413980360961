/**
 * Identity and Access Management
 * CIAM (Customer Identity and Access Management) API
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = MyOrganization__Info_Read, 1 = MyOrganization__Info_Write, 2 = MyOrganization__Info_Delete, 3 = MyOrganization__Members_Read, 4 = MyOrganization__Members_Write, 5 = MyOrganization__CustomRoles_Read, 6 = MyOrganization__CustomRoles_Write, 7 = MyOrganization__Products_Read, 8 = MyOrganization__Products_Install, 9 = MyOrganization__ProductBlueprints_Read, 10 = MyOrganization__ProductBlueprints_Write, 11 = MyOrganization__RoutineBlueprints_Read, 12 = MyOrganization__RoutineBlueprints_Write, 13 = MyOrganization__ApiKeys_Read, 14 = MyOrganization__ApiKeys_Write, 15 = MyOrganization__Installations_Read, 16 = MyOrganization__Installations_Write, 17 = MyOrganization__Installations_TechnicalSupport, 18 = ADMIN__OrganizationManagementRead, 19 = ADMIN__OrganizationManagementWrite
 */
export enum OrganizationPermissions {
    MyOrganization__Info_Read = 'my_organization__info_read',
    MyOrganization__Info_Write = 'my_organization__info_write',
    MyOrganization__Info_Delete = 'my_organization__delete_organization',
    MyOrganization__Members_Read = 'my_organization__members_read',
    MyOrganization__Members_Write = 'my_organization__members_write',
    MyOrganization__CustomRoles_Read = 'my_organization__custom_roles_read',
    MyOrganization__CustomRoles_Write = 'my_organization__custom_roles_write',
    MyOrganization__Products_Read = 'my_organization__products_read',
    MyOrganization__Products_Install = 'my_organization__products_install',
    MyOrganization__ProductBlueprints_Read = 'my_organization__product_blueprints_read',
    MyOrganization__ProductBlueprints_Write = 'my_organization__product_blueprints_write',
    MyOrganization__RoutineBlueprints_Read = 'my_organization__routine_blueprints_read',
    MyOrganization__RoutineBlueprints_Write = 'my_organization__routine_blueprints_write',
    MyOrganization__ApiKeys_Read = 'my_organization__api_keys_read',
    MyOrganization__ApiKeys_Write = 'my_organization__api_keys_write',
    MyOrganization__Installations_Read = 'my_organization__installations_read',
    MyOrganization__Installations_Write = 'my_organization__installations_write',
    MyOrganization__Installations_TechnicalSupport = 'my_organization__installations_technical_support',
    ADMIN__OrganizationManagementRead = 'admin__organization_management_read',
    ADMIN__OrganizationManagementWrite = 'admin__organization_management_write'
}

