import { Component, EventEmitter, input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-off-canvas-header',
  standalone: true,
  imports: [CommonModule],
  host: { 'style': 'display: contents' },
  templateUrl: './off-canvas-header.component.html',
})
export class OffCanvasHeaderComponent {
  title = input.required<string>();

  @Output()
  public dismissed = new EventEmitter<string>();

  dismissOffCanvas() {
    this.dismissed.emit("closed")
  }
}
