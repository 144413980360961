@if (config(); as sideNavConfig) {

  <ul class="side-nav">
    @for (section of sideNavConfig.sections; track $index) {

      <li class="side-nav-title">{{ section.title }}</li>

      @for (topLevelItem of section.items; track $index) {
        @if (hasSubItems(topLevelItem)) {
          <li class="side-nav-item" routerLinkActive="menuitem-active" *authGuard="topLevelItem.securityConstraints">
            <a data-bs-toggle="collapse"
               [routerLink]="topLevelItem.routerLink"
               aria-expanded="false"
               aria-controls="sidebarDashboards"
               (click)="collapse.toggle()"
               class="side-nav-link">
              <ui-svg-icon [icon]="topLevelItem.icon" size="normal" />
              <!--
                      <span class="badge bg-success rounded-pill float-end">5</span>
              -->
              <span> {{ topLevelItem.title }} </span>
              <span class="menu-arrow"></span>
            </a>

            <div class="collapse" id="sidebarDashboards" #collapse="ngbCollapse" [ngbCollapse]="true">
              <ul class="side-nav-second-level">
                @for (subItem of topLevelItem.subItems; track $index) {
                  <li routerLinkActive="menuitem-active">
                    <a [routerLink]="subItem.routerLink">{{ subItem.title }}</a>
                  </li>
                }
              </ul>
            </div>

          </li>
        } @else {
          <li class="side-nav-item" routerLinkActive="menuitem-active" [routerLinkActiveOptions]="{exact:true}">
            <a [routerLink]="topLevelItem.routerLink"
               class="side-nav-link">
              <ui-svg-icon [icon]="topLevelItem.icon" size="normal" />
              <!--
                      <span class="badge bg-success rounded-pill float-end">5</span>
              -->
              <span> {{ topLevelItem.title }} </span>
            </a>
          </li>
        }
      }
    }
  </ul>
}
