import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwistLogoComponent } from '../twist-logo/twist-logo.component';

@Component({
  selector: 'ui-loading',
  standalone: true,
  imports: [CommonModule, TwistLogoComponent],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent {
  showLogo = input(false)
}
